import classNames from "classnames";
import { ClassNameArgument } from "types";
import styles from "./Heading.module.css";

export type HeadingProps = {
  subtitle?: string;
  title?: string;
  className?: ClassNameArgument;
};

const Heading = ({ subtitle, title, className }: HeadingProps) => {
  return (
    <header className={classNames(styles["heading"], className)}>
      {title && <h2 className={styles["heading-title"]}>{title}</h2>}
      {subtitle && <p className={styles["heading-subtitle"]}>{subtitle}</p>}
    </header>
  );
};

export default Heading;
