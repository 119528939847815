import { useRouter } from "next/router";

const useLinkCanonical = () => {
  const { locale, pathname } = useRouter();

  return (
    <link
      rel="canonical"
      href={`${process.env.NEXT_PUBLIC_ORIGIN}/${locale}${pathname}`}
    />
  );
};

export default useLinkCanonical;
