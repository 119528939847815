import { gql } from "@apollo/client";
import { ARTICLE_FRAGMENT } from "./fragments/article";
import { PROJECT_FRAGMENT } from "./fragments/project";
import { TAG_FRAGMENT } from "./fragments/tag";

export const TAG_PAGE = gql`
  query TagPage(
    $locale: I18NLocaleCode
    $id: ID
    $articles_pagination: PaginationArg
    $projects_pagination: PaginationArg
  ) {
    tag(id: $id) {
      data {
        id
        attributes {
          name
          count
        }
      }
    }
    projects(
      locale: $locale
      pagination: $projects_pagination
      sort: ["createdAt:desc"]
      filters: {
        or: [
          { tags: { id: { eq: $id } } }
          { technologies: { id: { eq: $id } } }
        ]
      }
    ) {
      data {
        ...ProjectParts
      }
      meta {
        pagination {
          total
        }
      }
    }
    articles(
      locale: $locale
      pagination: $articles_pagination
      sort: ["date:desc"]
      filters: { tags: { id: { eq: $id } } }
    ) {
      data {
        ...ArticleParts
      }
    }
    tags(pagination: { limit: 20 }, sort: "count:desc") {
      data {
        ...TagParts
      }
    }
  }
  ${PROJECT_FRAGMENT}
  ${ARTICLE_FRAGMENT}
  ${TAG_FRAGMENT}
`;

export const TAGS = gql`
  query Tags($projectIds: [ID], $tagId: ID) {
    tags(
      filters: {
        and: { projects: { id: { in: $projectIds } }, id: { ne: $tagId } }
      }
    ) {
      data {
        ...TagParts
      }
    }
  }
  ${TAG_FRAGMENT}
`;

export const TAG_MUTATION = gql`
  mutation UpdateTag($data: TagInput!, $id: ID!) {
    updateTag(data: $data, id: $id) {
      data {
        id
      }
    }
  }
`;
