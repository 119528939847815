import { gql } from "@apollo/client";

export const TAG_FRAGMENT = gql`
  fragment TagParts on TagEntity {
    id
    attributes {
      name
      count
      articles {
        data {
          id
          attributes {
            date
          }
        }
      }
      projects {
        data {
          id
          attributes {
            name
          }
        }
      }
      projectsTechnologies {
        data {
          id
          attributes {
            name
          }
        }
      }
      services {
        data {
          id
          attributes {
            name
          }
        }
      }
      createdAt
      updatedAt
      publishedAt
    }
  }
`;
