import classNames from "classnames";
import { useMemo } from "react";
import { TAG_SIZE_THRESHOLDS } from "common/constants";
import Tag from "components/Tag";
import { TagSize } from "types";
import { TagPartsFragment } from "types/graphql";
import styles from "./TagList.module.css";

type TagListProps = {
  tags?: TagPartsFragment[] | null;
  limit?: number;
  className?: string;
  popularity?: boolean;
};

const TagList = ({ tags, limit = -1, className, popularity }: TagListProps) => {
  const availableSizes = useMemo(
    () =>
      (Object.keys(TAG_SIZE_THRESHOLDS) as TagSize[]).filter(
        (size) => tags?.[0]?.attributes?.count ?? 0 <= TAG_SIZE_THRESHOLDS[size]
      ),
    [tags]
  );

  const orderedTags = useMemo(
    () =>
      (tags ?? [])
        .slice(0, limit)
        .map((tag, index) => {
          let size: TagSize | undefined;
          if (popularity) {
            const tagCount = tag?.attributes?.count ?? 0;

            let upperBoundSizeIndex = availableSizes.findIndex(
              (size) => tagCount - TAG_SIZE_THRESHOLDS[size] < 0
            );

            upperBoundSizeIndex =
              upperBoundSizeIndex < 0
                ? availableSizes.length - 1
                : upperBoundSizeIndex;

            const lowerBoundSizeIndex =
              upperBoundSizeIndex === 0
                ? upperBoundSizeIndex
                : upperBoundSizeIndex - 1;

            const upperThreshold =
              TAG_SIZE_THRESHOLDS[availableSizes[upperBoundSizeIndex]];
            const lowerThreshold =
              TAG_SIZE_THRESHOLDS[availableSizes[lowerBoundSizeIndex]];

            const sizeIndex =
              Math.abs(upperThreshold - tagCount) <
              Math.abs(lowerThreshold - tagCount)
                ? lowerBoundSizeIndex
                : upperBoundSizeIndex;

            size = availableSizes[sizeIndex];

            if (availableSizes.length > 1) {
              availableSizes.splice(sizeIndex, 1);
            }
          }

          return {
            tag,
            size,
            index,
          };
        })
        .sort((a, b) => {
          return (
            (a.index % 2 ? a.index - b.index : b.index - a.index) ||
            (b.index % 2) - (a.index % 2)
          );
        }),
    [availableSizes, limit, popularity, tags]
  );

  return (
    <ul className={classNames(styles["tag-list"], className)}>
      {orderedTags.map(({ tag, size }) => {
        return (
          <li key={tag.id}>
            <Tag tag={tag} size={size} />
          </li>
        );
      })}
    </ul>
  );
};

export default TagList;
