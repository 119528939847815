import { useRouter } from "next/router";
import { PageName } from "types";

export type UseLinkAlternateParameters = {
  locales?: string[];
  hrefTransformer?: (locale: string) => string;
};

export type CmsEntryLocalizationResponse = {
  data?:
    | {
        id?: string | null;
        attributes?: {
          locale?: string | null;
        } | null;
      }[]
    | null;
};

export const cmsEntryIdBasedHrefTransformer = (
  pageName: PageName,
  localizations?: CmsEntryLocalizationResponse | null
) => {
  return (locale: string) => {
    const localizedEntryId =
      localizations?.data?.find((entry) => entry.attributes?.locale === locale)
        ?.id ?? "";

    return `${process.env.NEXT_PUBLIC_ORIGIN}/${locale}/${pageName}/${localizedEntryId}`;
  };
};

const useLinkAlternate = ({
  locales,
  hrefTransformer,
}: UseLinkAlternateParameters = {}) => {
  const {
    locale: currentLocale,
    locales: defaultLocales = [],
    pathname,
  } = useRouter();

  const nonCurrentLocales = (locales ?? defaultLocales).filter(
    (locale) => locale !== currentLocale
  );

  return (
    <>
      {nonCurrentLocales.map((locale) => (
        <link
          key={locale}
          rel="alternate"
          hrefLang={locale}
          href={
            hrefTransformer?.(locale) ||
            `${process.env.NEXT_PUBLIC_ORIGIN}/${locale}${pathname}`
          }
        />
      ))}
    </>
  );
};

export default useLinkAlternate;
