import { gql } from "@apollo/client";
import { TAG_FRAGMENT } from "./tag";

export const ARTICLE_FRAGMENT = gql`
  fragment ArticleParts on ArticleEntity {
    id
    attributes {
      title
      coverImage {
        data {
          attributes {
            url
          }
        }
      }
      date
      tags {
        data {
          ...TagParts
        }
      }
      createdAt
      updatedAt
      publishedAt
      locale
    }
  }
  ${TAG_FRAGMENT}
`;
