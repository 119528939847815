import classNames from "classnames";
import { CSSProperties, forwardRef, PropsWithChildren } from "react";
import Heading, { HeadingProps } from "components/Heading";
import { ClassNameArgument } from "types";
import styles from "./Section.module.css";

type SectionProps = HeadingProps & {
  className?: ClassNameArgument;
  id?: string;
  hero?: boolean;
  "reduced-block-padding"?: boolean;
  "extra-block-padding"?: boolean;
  "no-padding"?: boolean;
  style?: CSSProperties;
  "page-padding-top"?: boolean;
};

const Section = forwardRef<HTMLElement, PropsWithChildren<SectionProps>>(
  (
    {
      className,
      title,
      children,
      subtitle,
      id,
      hero,
      style,
      "reduced-block-padding": reducedPadding,
      "extra-block-padding": extraPadding,
      "no-padding": noPadding,
      "page-padding-top": pagePaddingTop,
    },
    ref
  ) => {
    return (
      <section
        ref={ref}
        id={id}
        className={classNames(styles.section, className, { hero })}
        data-reduced-block-padding={reducedPadding}
        data-extra-block-padding={extraPadding}
        data-no-padding={noPadding}
        style={style}
        data-page-padding-top={pagePaddingTop}
      >
        <Heading title={title} subtitle={subtitle} />
        {children}
      </section>
    );
  }
);
export default Section;
