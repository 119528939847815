import { gql } from "@apollo/client";
import { TAG_FRAGMENT } from "./tag";

export const PROJECT_FRAGMENT = gql`
  fragment ProjectParts on ProjectEntity {
    id
    attributes {
      image {
        data {
          id
          attributes {
            name
            caption
            hash
            mime
            provider
            size
            url
          }
        }
      }
      name
      tags {
        data {
          ...TagParts
        }
      }
      description
      type
      startDate
      endDate
      client {
        data {
          attributes {
            name
            websiteUrl
          }
        }
      }
      projectUrl
      references {
        data {
          id
          attributes {
            fullName
          }
        }
      }
      technologies {
        data {
          id
          attributes {
            name
          }
        }
      }
      gallery {
        data {
          id
          attributes {
            url
          }
        }
      }
      createdAt
      updatedAt
      publishedAt
      locale
    }
  }
  ${TAG_FRAGMENT}
`;
