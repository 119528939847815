import classNames from "classnames";
// eslint-disable-next-line import/order
import Icon from "components/Icon";
import { useState } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ClassNameArgument } from "types";
import styles from "./Carousel.module.css";

export type CarouselProps = { className?: ClassNameArgument } & Settings;

const Carousel = ({
  children,
  className,
  dots = true,
  infinite = false,
  arrows = false,
  ...props
}: CarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Slider
      {...props}
      className={classNames(styles.carousel, className)}
      arrows={arrows}
      dots={dots}
      infinite={infinite}
      dotsClass={styles["carousel-dots"]}
      beforeChange={(_, newActive) => setActiveIndex(newActive)}
      appendDots={(dots) => <ul>{dots}</ul>}
      nextArrow={<Icon name="arrow-right" />}
      prevArrow={<Icon name="arrow-right" />}
      customPaging={(index: number) => (
        <button
          aria-label={`Go to slide ${index}`}
          className={styles["carousel-dot"]}
          data-active={index === activeIndex}
        />
      )}
    >
      {children}
    </Slider>
  );
};

export default Carousel;
