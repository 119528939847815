import classNames from "classnames";
import Link from "next/link";
import Img from "components/Img";
import TagList from "components/TagList";
import { ClassNameArgument } from "types";
import { ProjectPartsFragment } from "types/graphql";
import styles from "./ProjectTile.module.css";

type ProjectTileProps = {
  data: ProjectPartsFragment;
  className?: ClassNameArgument;
};

const ProjectTile = ({ className, data: project }: ProjectTileProps) => {
  const imageSrc = project?.attributes?.image?.data?.attributes?.url ?? "";
  const title = project?.attributes?.name ?? "";
  const tags = project?.attributes?.tags?.data ?? [];

  return (
    <Link
      className={classNames(styles["container"], "inherit-color")}
      href={`/projects/${project.id}`}
    >
      <article
        className={classNames(styles["project-tile"], "shadow", className)}
      >
        {imageSrc && (
          <figure className={styles["cover-image"]}>
            <Img width={320} height={180} alt={title} src={imageSrc} />
          </figure>
        )}
        <p className={styles["project-tile-title"]} data-line-clamp>
          {title}
        </p>
        <TagList limit={6} tags={tags} className={styles["tag-list"]} />
      </article>
    </Link>
  );
};

export default ProjectTile;
