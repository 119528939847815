import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { CSSProperties, MouseEvent, useCallback } from "react";
import { toast } from "react-toastify";
import { TAG_MUTATION } from "graphql/tag-page";
import { TagSize } from "types";
import {
  TagPartsFragment,
  UpdateTagMutation,
  UpdateTagMutationVariables,
} from "types/graphql";
import styles from "./Tag.module.css";

type TagProps = {
  tag: TagPartsFragment;
  size?: TagSize;
};

const FONT_SIZES = Object.freeze({
  xs: "0.625rem",
  sm: "0.75rem",
  md: "0.875rem",
  lg: "1rem",
  xl: "1.125rem",
  xxl: "1.25rem",
});

const Tag = ({ tag, size = "sm" }: TagProps) => {
  const { t } = useTranslation("common");

  const router = useRouter();

  const [updateTag] = useMutation<
    UpdateTagMutation,
    UpdateTagMutationVariables
  >(TAG_MUTATION);

  const handleUpdate = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      updateTag({
        variables: {
          data: { count: (tag?.attributes?.count ?? 0) + 1 },
          id: tag?.id ?? "",
        },
      })
        .catch((error) => {
          console.error(error);
          toast.error(t("tag-update-error"));
        })
        .finally(() => {
          router.push(`/tag/${tag.id}`);
        });
    },
    [router, t, tag?.attributes?.count, tag.id, updateTag]
  );

  return (
    <button
      className={styles.tag}
      onClick={handleUpdate}
      style={
        {
          "--size": FONT_SIZES[size],
        } as CSSProperties
      }
    >
      #{tag.attributes?.name}
    </button>
  );
};

export default Tag;
